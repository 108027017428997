// Initialize service worker
function listenForWaitingServiceWorker(reg, callback) {
  function awaitStateChange() {
    reg.installing.addEventListener('statechange', function() {
        console.log("here", this.state)
      if (this.state === 'installed') callback(reg);
    });
  }
  if (!reg) return;
  if (reg.waiting) return callback(reg);
  if (reg.installing) awaitStateChange();
  reg.addEventListener('updatefound', awaitStateChange);
}

function promptUserToRefresh() {
  return reg => {
      if (reg.waiting) {
          reg.waiting.postMessage({type: 'SKIP_WAITING'});
      }
  }
}

export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    (async () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const registration = await navigator.serviceWorker.register(swUrl);
      console.log(`Registered service worker with scope ${registration.scope}.`);

      listenForWaitingServiceWorker(registration, promptUserToRefresh());
    })().catch(err => console.log(`Service worker registration failed: ${err}`));

    // reload once when the new Service Worker starts activating
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange',
        () => {
          if (refreshing) return;
          refreshing = true;
          window.location.reload();
        }
    );
  }
}

