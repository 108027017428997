import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <App />,
  document.getElementById('root')

);
if(process.env.NODE_ENV === "production") {
    serviceWorker.registerServiceWorker();
}
